// out:false

@mixin Fade {
  &-10 {
    opacity: .1;
  }

  &-20 {
    opacity: .2;
  }

  &-30 {
    opacity: .3;
  }

  &-40 {
    opacity: .4;
  }

  &-50 {
    opacity: .5;
  }

  &-60 {
    opacity: .6;
  }

  &-70 {
    opacity: .7;
  }

  &-80 {
    opacity: .8;
  }

  &-90 {
    opacity: .9;
  }
}

.fade {
  @include AddMedia {
    @include Fade;

  }
  
}