// out:false

.flex {
	display: flex;


	&-align {

		&-stretch {
			align-items: stretch;
		}

		&-center {
			align-items: center;
		}

		&-start {
			align-items: flex-start;
		}

		&-end {
			align-items: flex-end;
		}
	}

	&-justify {
		&-center {
			justify-content: center;
		}

		&-start {
			justify-content: flex-start;
		}

		&-end {
			justify-content: flex-end;
		}

		&-between {
			justify-content: space-between;
		}

		&-around {
			justify-content: space-around;
		}
	}

	&-self {
		&-start {
			align-self: flex-start;
		}

		&-end {
			align-self: flex-end;
		}

		&-stretch {
			align-self: stretch;
		}

		&-center {
			align-self: center;
		}
	}

	&-grow {
		flex-grow: 1;
	}

	&-wrap {
		flex-wrap: wrap;
	}
}