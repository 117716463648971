// out: false



.form {
	&-field {
		display      : block;
		cursor       : pointer;
		position     : relative;
		--error-color: var(--color-red);
		--field-color: var(--color-primary);
	}

	&-helper {
		font-size  : 12px;
		width      : max-content;
		margin-left: auto;
		margin-top : 2px;
		color      : var(--color-text-60);
	}

	&-input-cover {
		display    : flex;
		align-items: center;
		position   : relative;
		transition : .2s ease;


	}


	&-input {
		outline  : none !important;
		display  : flex;
		height   : 48px;
		position : relative;
		padding  : calc(var(--spacing) * 2) calc(var(--spacing) * 4);
		flex-grow: 1;

		--radius-small: 12px;

		cursor    : inherit;
		outline   : none;
		appearance: none;

		font-size  : 14px;
		font-family: var(--font-base);
		color      : var(--color-text);

		background-color: var(--bg3);
		border          : 1px solid var(--color-text-10);

		&:first-of-type {
			border-start-start-radius: var(--radius-small);
			border-end-start-radius  : var(--radius-small);
		}

		&:last-of-type {
			border-end-end-radius  : var(--radius-small);
			border-start-end-radius: var(--radius-small);
		}

		&:not(:first-of-type) {
			margin-left: -1px;
		}


		&:focus {
			border-color: var(--color-icon);
			z-index     : 2;
		}

		&:disabled {
			color           : var(--color-text-60);
			border          : 1px solid var(--color-text-10);
			background-color: var(--bg2);
			opacity         : .8;

			&:placeholder {
				color: var(--color-text-60);
			}
		}



		&::placeholder {
			opacity: 1;
		}

		&[type="date"]::-webkit-calendar-picker-indicator,
		&[type="time"]::-webkit-calendar-picker-indicator {
			background    : none;
			pointer-events: none;
		}



		&.active {
			border-color: var(--color-primary);
			box-shadow  : 0 0 0 1px var(--color-primary);
			z-index     : 2;
		}

		&.error {
			color       : var(--color-red);
			border-color: var(--color-red);
			z-index     : 2;

			&::placeholder {
				color: var(--color-red);
			}
		}


		&:is(input) {
			width         : 100px;
			// line-height: 40px;
		}

		&:is(textarea) {
			min-height: 128px;
		}
	}

	&-input-start,
	&-input-end {
		position   : absolute;
		top        : 0;
		height     : 100%;
		display    : flex;
		align-items: center;
		z-index    : 3;
	}

	&-input-start {
		left: 0;
	}

	&-input-end {
		right: 0;
	}

	&-checkbox {
		display    : flex;
		align-items: center;
	}


	&-input-checkbox {
		position: absolute;
		left    : 0;
		top     : 0;
		width   : 0;
		height  : 0;
		overflow: hidden;
		padding : calc(var(--spacing) * 2);

		background: none;
		border    : none;
		outline   : none;
		box-shadow: none;
		z-index   : -1;
	}

	&-toggle-target {
		--cover-width : 46px;
		--cover-height: 26px;
		--padding     : 2px;
		width         : var(--cover-width);
		min-width     : var(--cover-width);
		height        : var(--cover-height);
		background    : var(--color-text-40);
		border-radius : 20px;
		position      : relative;
		overflow      : hidden;

		&:before {
			--size: 22px;

			content      : '';
			width        : var(--size);
			height       : var(--size);
			border-radius: 50%;
			background   : var(--bg3);

			box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
			display   : block;

			transition: .2s ease;

			position: absolute;
			left    : var(--padding);
			top     : calc(50% - var(--size) / 2);
		}
	}

	&-input-checkbox:checked~&-toggle-target {
		background: var(--color-primary);

		&::before {
			transform: translateX(calc(var(--cover-width) - var(--size) - var(--padding) - var(--padding)));
		}
	}

	&-checkbox-target {
		// background         : linear-gradient(180deg, #FFFFFF 0%, rgba(176, 176, 176, 0.51) 100%), #FFFFFF;
		background         : var(--bg3);
		border             : 1px solid var(--color-text-40);
		border-radius      : 4px;
		background-position: center;
		display            : block;
		min-width          : 18px;
		width              : 18px;
		height             : 18px;

		display        : flex;
		align-items    : center;
		justify-content: center;

		.icon {
			font-size     : 12px;
			color         : transparent;
			pointer-events: none;
			transition    : .2s ease;
			transform     : scale(0);
		}




	}

	&-input-checkbox:checked~&-checkbox-target {
		animation: check .3s forwards;

		.icon {
			color    : var(--color-primary);
			transform: scale(1);
		}
	}

	&-radio-target {
		// background         : linear-gradient(180deg, #FFFFFF 0%, rgba(176, 176, 176, 0.51) 100%), #FFFFFF;
		background         : var(--bg3);
		border             : 1px solid var(--color-text-40);
		background-position: center;
		min-width          : 18px;
		width              : 18px;
		height             : 18px;
		border-radius      : 50%;


		display        : flex;
		align-items    : center;
		justify-content: center;



		&:before {
			content      : '';
			width        : 10px;
			height       : 10px;
			border-radius: 50%;
			display      : block;
			margin       : auto;
			background   : var(--color-primary);

			transition: .2s ease;
			opacity   : 0;
			transform : scale(0);
		}



	}

	&-input-checkbox:checked+&-radio-target {
		// border-color: var(--color-primary);
		// background  : var(--color-primary);
		animation: check .3s forwards;

		&:before {
			transform: none;
			opacity  : 1;
		}
	}


	@media (hover) {

		&-field:hover &-checkbox-target,
		&-field:hover &-radio-target {
			box-shadow: 0 0 0 5px var(--color-primary-10);
		}
	}

	&-checkbox &-input-checkbox.disabled~&-toggle-target,
	&-checkbox &-input-checkbox:disabled~&-toggle-target,
	&-checkbox &-input-checkbox.disabled~&-radio-target,
	&-checkbox &-input-checkbox:disabled~&-radio-target,
	&-checkbox &-input-checkbox.disabled~&-checkbox-target,
	&-checkbox &-input-checkbox:disabled~&-checkbox-target {
		box-shadow    : none;
		pointer-events: none;
		opacity       : .5;
	}


	&-select {
		&-icon {
			position      : absolute;
			font-size     : 10px;
			right         : 10px;
			top           : calc(50% - 5px);
			color         : var(--color-icon);
			z-index       : 3;
			pointer-events: none;
		}
	}

}

@keyframes check {
	0% {
		box-shadow: 0 0 0 0 var(--field-color);
	}

	100% {
		box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
	}
}

.range {
	cursor  : pointer;
	position: relative;


	&-inner {
		// padding: 20px;
		// margin : -20px;
		padding-top   : 12px;
		padding-bottom: 12px;
		position      : relative;

		position: absolute;
		left    : 0;
		width   : 100%;
		top     : -12px;
	}

	&-btn {
		display      : flex;
		background   : var(--color-primary);
		border       : 3px solid var(--bg3);
		box-shadow   : 0px 1px 1px rgba(0, 0, 0, 0.24);
		position     : absolute;
		width        : 22px;
		height       : 22px;
		border-radius: 50%;

		left          : 0;
		top           : calc(50% - 11px);
		transform     : translateX(-50%);
		pointer-events: none;
		transition    : .1s;
	}

	&-line {
		height    : 5px;
		background: var(--bg2);

		border-radius: 0px 0px 6px 6px;
	}

	&-progress {
		position      : absolute;
		left          : 0;
		top           : 12px;
		height        : 5px;
		background    : var(--color-primary);
		border-radius : 0px 0px 6px 6px;
		pointer-events: none;
		transition    : .1s;
	}

	&-value {
		font-family: Arial, Helvetica, sans-serif;
	}

}

.form-range {
	position: absolute;
	left    : -1px;
	bottom  : 5px;
	width   : calc(100% + 2px);
}