// out: false, compress: true

.example-cover {
  display    : flex;
  align-items: flex-start;
}

.example-btns {
  min-width: 300px;
  width    : 300px;
  padding  : 16px;
  position : sticky;
  top      : 0;
}

.example-content {
  width: 100%;
}

.code{
  padding: 10px;
  background: #333;
  color: #fff;
}