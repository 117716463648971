// out:false




.image {
	overflow: hidden;
	display : block;
	position: relative;

	img {
		width     : 100%;
		height    : 100%;
		object-fit: cover;

		position      : relative;
		z-index       : 1;
		user-select   : none;
		pointer-events: none;
	}


	&--box,
	&--rect {
		&:before {
			content: '';
			display: block;
			width  : 100%;
		}

		img {
			position: absolute;
			left    : 0;
			top     : 0;


		}
	}

	&--box {
		&:before {
			padding-bottom: 100%;
		}


	}


	&--rect {
		&:before {
			padding-bottom: calc(100% * 3 / 4);
		}


	}

	&--cover {
		img {
			object-fit: cover;
		}
	}

	&--contain {
		img {
			object-fit: contain;
		}
	}

	&--scale-down {
		img {
			object-fit: scale-down;
		}
	}

	&--round {
		border-radius: 50%;
	}

}