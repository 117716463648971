// out:false
.dialog {
  &-large {
    width    : 970px;
    max-width: 100%;

    @media (max-width:$sm) {
      width: 100%;
    }

    @media(max-width:$md) {}
  }

  &-small {
    width    : 420px;
    max-width: 100%;
  }

  &-middle {
    width    : 620px;
    max-width: 100%;

    @media (max-width:$sm) {
      width: 100%;
    }

  }
}