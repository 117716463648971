// out: false



.icon {
	min-width: 1em;
	width    : 1em;
	height   : 1em;
	display  : block;

}
