// out:false
@mixin Height {
  &-100 {
    height: 100%;
  }

  &-50 {
    height: 50%;
  }

  &-auto {
    height: auto !important;
  }
}

.h {
  @include AddMedia{
    @include Height;

  }

}