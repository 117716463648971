// out:false



.bg {
  &-l1 {
    background: var(--bg1);
  }

  &-l2 {
    background: var(--bg2);
  }

  &-l3 {
    background: var(--bg3);
  }

  &-white {
    background: #fff;

    &-20 {
      background: fade(#fff, 20);
    }

    &-40 {
      background: fade(#fff, 40);
    }


    &-60 {
      background: fade(#fff, 60);
    }


    &-80 {
      background: fade(#fff, 80);
    }

  }

  &-black {
    background: #000;

    &-20 {
      background: fade(#000, 20);
    }


    &-40 {
      background: fade(#000, 40);
    }


    &-60 {
      background: fade(#000, 60);
    }


    &-80 {
      background: fade(#000, 80);
    }

  }


  &-primary {
    background: var(--color-primary);

    &-20 {
      background: var(--color-primary-20);
    }


    &-40 {
      background: var(--color-primary-40);
    }


    &-60 {
      background: var(--color-primary-60);
    }


    &-80 {
      background: var(--color-primary-80);
    }

  }

  &-sec {
    background: var(--color-sec);

    &-20 {
      background: var(--color-sec-20);
    }


    &-40 {
      background: var(--color-sec-40);
    }


    &-60 {
      background: var(--color-sec-60);
    }


    &-80 {
      background: var(--color-sec-80);
    }

  }

  &-blue {
    background: var(--color-blue);

    &-20 {
      background: var(--color-blue-20);
    }

    &-40 {
      background: var(--color-blue-40);
    }

    &-60 {
      background: var(--color-blue-60);
    }

    &-80 {
      background: var(--color-blue-80);
    }

  }

  &-red {
    background: var(--color-red);

    &-20 {
      background: var(--color-red-20);
    }

    &-40 {
      background: var(--color-red-40);
    }

    &-60 {
      background: var(--color-red-60);
    }

    &-80 {
      background: var(--color-red-80);
    }

  }

  &-green {
    background: var(--color-green);


    &-20 {
      background: var(--color-green-20);
    }

    &-40 {
      background: var(--color-green-40);
    }

    &-60 {
      background: var(--color-green-60);
    }

    &-80 {
      background: var(--color-green-80);
    }

  }

  &-yellow {
    background: var(--color-yellow);

    &-20 {
      background: var(--color-yellow-20);
    }

    &-40 {
      background: var(--color-yellow-40);
    }

    &-60 {
      background: var(--color-yellow-60);
    }

    &-80 {
      background: var(--color-yellow-80);
    }

  }

  &-text {
    background: var(--color-text);
  }

  &-icon {
    background: var(--color-icon);
  }

}