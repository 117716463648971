// out: false



.color {
	&-blue {
		color: var(--color-blue);
	}

	&-green {
		color: var(--color-green);
	}

	&-yellow {
		color: var(--color-yellow);
	}

	&-red {
		color: var(--color-red);
	}

	&-primary {
		color: var(--color-primary);
	}

	&-sec {
		color: var(--color-sec);
	}

	&-white {
		color: #fff;
	}

	&-black {
		color: #000;
	}

	&-icon {
		color: var(--color-icon);
	}


	&-text {
		color: var(--color-text);
	}

	&-hover {
		&-blue {
			@media(hover) {
				&:hover {
					color: var(--color-blue);
				}
			}
		}

		&-green {
			@media(hover) {
				&:hover {
					color: var(--color-green);
				}
			}
		}

		&-yellow {
			@media(hover) {
				&:hover {
					color: var(--color-yellow);
				}
			}
		}

		&-red {
			@media(hover) {
				&:hover {
					color: var(--color-red);
				}
			}
		}

		&-primary {
			@media(hover) {
				&:hover {
					color: var(--color-primary);
				}
			}
		}

		&-white {
			@media(hover) {
				&:hover {
					color: #fff;
				}
			}
		}

		&-icon {
			@media(hover) {
				&:hover {
					color: var(--color-icon);
				}
			}
		}

		&-black {
			@media(hover) {
				&:hover {
					color: #000;
				}
			}
		}

		&-text {
			@media(hover) {
				&:hover {
					color: var(--color-text);
				}
			}
		}
	}
}