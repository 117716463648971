// out:false


.bd {
  &-right {
    border-right: 1px solid #E2E8F0;
  }

  &-left {
    border-left: 1px solid #E2E8F0;
  }

  &-top {
    border-top: 1px solid #E2E8F0;
  }

  &-bottom {
    border-bottom: 1px solid #E2E8F0;
  }

  &-all {
    border: 1px solid #E2E8F0;
  }

  &-white {
    border-color: #fff;
  }

  &-black {
    border-color: #000;
  }

  &-primary {
    border-color: var(--color-primary);
  }

  &-blue {
    border-color: var(--color-blue);
  }

  &-red {
    border-color: var(--color-red);
  }

  &-green {
    border-color: var(--color-green);
  }

  &-yellow {
    border-color: var(--color-yellow);
  }

  &-text {
    border-color: var(--color-text);
  }

  &-icon {
    border-color: var(--color-icon);
  }
}


.brs-1 {
	border-radius: var(--radius-large);
}

.brs-2 {
	border-radius: var(--radius-middle);
}

.brs-3 {
	border-radius: var(--radius-small);
}