@import '../ui/scss/_variables.scss';
@import '../ui/scss/_functions.scss';

:root {
	@include getColors(#B0141B, "primary");
	@include getColors(#FFDA7B, "sec");
	@include getColors(#0092E4, "blue");
	@include getColors(#F8BE2A, "yellow");
	@include getColors(#31A043, "green");
	@include getColors(#EB5757, "red");
	@include getColors(#828A99, "icon");
	@include getColors(#1E1E1E, "text");

	--bg1: #f1f1f1;
	--bg2: #fafafa;
	--bg3: #FFFFFF;

	--spacing: 4px;

	--radius-small : 12px;
	--radius-middle: 12px;
	--radius-large : 12px;

	--media-lg: #{$lg};
	--media-md: #{$md};
	--media-sm: #{$sm};

	--font-base: 'Inter', Arial, Helvetica, sans-serif;
	--font-alt : 'Oranienbaum', 'Times New Roman', Times, serif;

	--zi-l1: 321;
	--zi-l2: 322;
	--zi-l3: 323;
	--zi-l4: 324;
	--zi-l5: 325;
	--zi-l6: 326;
	--zi-l7: 327;
	--zi-l8: 328;
	--zi-l9: 329;



	--root-from-2: 1.41421356237;
}

[data-theme="dark"] {
	@include getColors(#ffffff, text);

	--bg1: #181818;
	--bg2: #282828;
	--bg3: #323232;
}

.fancybox__content {
	padding   : 0;
	background: none;
	max-width : 100%;

	&>.carousel__button.is-close {
		top  : 0;
		color: var(--color-text);
	}
}

body {
	color         : var(--color-text);
	min-height    : 100vh;
	display       : flex;
	flex-direction: column;

	* {
		font-family: var(--font-base);
	}

}

.active-enabled {
	display: none !important;
}

.toggle-active,
.tab-active,
.active {
	.active-disabled {
		display: none !important;
	}

	.active-enabled {
		display: flex !important;
	}
}

.title {
	font-size  : 56px;
	text-align : center;
	font-weight: 400;

	@media(max-width: $md) {
		font-size: 36px;
	}
}

.header {
	display       : flex;
	align-items   : center;
	background    : var(--bg3);
	padding-top   : 14px;
	padding-bottom: 14px;

	position: sticky;
	top     : 0;
	z-index : var(--zi-l1);

	@media(max-width:$md) {
		padding-top   : 6px;
		padding-bottom: 6px;
	}

	&-logo {
		display    : flex;
		align-items: center;

		&__img {
			margin-right: 20px;
			width       : 46px;

			@media(max-width:$md) {
				margin-right: 12px;
				width       : 36px;
			}

			@media(max-width:$sm) {
				margin-right: 0;
			}
		}

		&__title {
			font-weight: 500;
			font-size  : 22px;

			@media(max-width:$sm) {
				display: none;
			}
		}

		&__subtitle {
			font-weight: 400;
			font-size  : 14px;
			opacity    : .6;

			@media(max-width:$md) {
				display: none;
			}
		}
	}

	&-contact {
		display    : flex;
		align-items: center;

		&__img {
			min-width    : 42px;
			width        : 42px;
			height       : 42px;
			border-radius: 12px;
			margin-right : 20px;

			display        : flex;
			align-items    : center;
			justify-content: center;
			color          : #fff;
			font-size      : 18px;

			@media(max-width:$md) {
				min-width   : 36px;
				width       : 36px;
				height      : 36px;
				margin-right: 12px;
			}
		}

		&--address {
			@media(max-width:$lg) {
				display: none;
			}
		}

		&--address &__img {
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, rgba(102, 12, 17, 0.4) 100%), #E84139;


		}

		&--phone &__img {
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, rgba(102, 12, 17, 0.4) 100%), #E71B24;
		}
	}

}

.advantages {
	padding-top     : 67px;
	padding-bottom  : 50px;
	background-color: var(--bg3);

	&__title {
		margin-bottom: 70px;
	}

	&__items {
		display              : grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap             : 50px;
	}

	&__item {
		display    : flex;
		align-items: center;
	}

	&__pic {
		min-width      : 75px;
		height         : 75px;
		display        : flex;
		align-items    : center;
		justify-content: center;
		background     : linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, rgba(102, 12, 17, 0.4) 100%), #E84139;
		border-radius  : 12px;
		font-size      : 52px;
	}

	@media(max-width: $lg) {
		&__items {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	@media(max-width: $md) {
		& {
			padding-top   : 40px;
			padding-bottom: 25px;
		}

		&__title {
			margin-bottom: 50px;
		}

		&__items {
			grid-template-columns: 1fr;
			grid-gap             : 30px;
			max-width            : 330px;
			margin               : 0 auto;
		}
	}
}

.started {
	background    : linear-gradient(83.28deg, #660C11 0%, rgba(0, 0, 0, 0) 100%), #E71B24;
	background    : linear-gradient(83.28deg, #660C11 0%, rgba(0, 0, 0, 0) 100%), #E84139;
	position      : relative;
	display       : flex;
	align-items   : center;
	padding-top   : 82px;
	padding-bottom: 86px;
	overflow      : hidden;
	height: 648px;

	@media(max-width: $lg) {
		flex-direction: column;
		padding-top   : 32px;
		padding-bottom: 32px;
	}

	@media(max-width: $md) {
		padding-bottom: 15px;
		height: 400px;
		// justify-content: center;
	}

	&__content {
		color         : #FFFFFF;
		display       : flex;
		flex-direction: column;
		align-items   : flex-start;
		position      : relative;
		z-index       : 2;

		@media(max-width: $lg) {
			align-items: center;
		}
	}

	&__badge {
		display      : flex;
		align-items  : center;
		margin-bottom: 28px;
		background   : var(--bg3);
		color        : var(--color-text);
		border-radius: 60px;
		font-weight  : 600;
		font-size    : 14px;
		padding      : 5px 12px;

		@media(max-width: $lg) {
			display: none;
		}
	}

	&__title {
		font-family: var(--font-alt);
		font-weight: 400;
		font-size  : 56px;

		@media(max-width: $lg) {
			text-align : center;
			line-height: 1;
			font-size  : 48px;
		}

		@media(max-width: $md) {
			font-size: 36px;

			br {
				display: none;
			}
		}

		@media(max-width: $sm) {
			font-size: 28px;
		}

		span {
			font-family: inherit;
		}
	}

	&__description {
		margin-top: 28px;

		@media(max-width: $lg) {
			margin-top: 24px;
			text-align: center;
		}

		@media(max-width: $md) {
			font-size : 14px;
			margin-top: 15px;
		}

		@media(max-width: $sm) {
			// display: none;
			br{
				display: none;
			}
		}
	}

	&__form {
		min-width    : 420px;
		width        : 420px;
		padding      : 48px 42px;
		border-radius: 12px;
		margin-left  : auto;
		background   : var(--bg3);
		border       : 1px solid var(--bg1);
		box-shadow   : 0px 16px 20px rgba(35, 35, 35, 0.08);
		position     : relative;
		z-index      : 2;

		@media(max-width: $lg) {
			margin-left   : 0;
			margin-top    : 40px;
			padding-top   : 24px;
			padding-bottom: 24px;
			width         : 100%;
			min-width     : 0;
		}

		@media(max-width: $md) {
			padding-left : 15px;
			padding-right: 15px;
			margin-top   : 32px;

		}

		&-fields {
			@media(max-width: $lg) {
				display              : grid;
				grid-template-columns: 1fr 1fr;
				grid-gap             : 15px;

			}

			@media(max-width: $sm) {
				grid-template-columns: 1fr;
			}
		}
	}

	&-tiles {
		position             : absolute;
		display              : grid;
		grid-template-columns: repeat(5, 100px);
		grid-template-rows   : repeat(4, 100px);

		@media(max-width: $lg) {
			grid-template-columns: repeat(5, 80px);
			grid-template-rows   : repeat(4, 80px);
		}

		@media(max-width: $md) {
			grid-template-columns: repeat(5, 50px);
			grid-template-rows   : repeat(4, 50px);
		}

		&--left {
			top : 0;
			left: 0;
		}

		&--right {
			right : 0;
			bottom: 0;
		}
	}

	&-tile {
		background: url("../img/started-tile.svg") no-repeat center / contain;
		opacity   : 0.07;
		margin    : -1px;
	}

	&-stone {
		position: absolute;
		bottom  : 0;
		display : block;

		@media(max-width: $md) {
			// display: none;
		}

		&--full {
			right: 0;
			@media(max-width: $lg){
				bottom: -15%;
			}
			
			@media(max-width: $sm){
				bottom: 0;
			}
		}

		&--1 {
			width  : 850px;
			height : 283px;
			left   : 34%;
			z-index: 1;


			@media(max-width: $lg) {
				left: 0;
			}
		}

		&--2 {
			width : 710px;
			height: 262px;
			left  : 65%;

			@media(max-width: $lg) {
				left : auto;
				right: 0;
			}
		}
	}
}

.approach {
	padding-top     : 50px;
	padding-bottom  : 35px;
	background-color: var(--bg3);

	&__block {
		background   : linear-gradient(250.97deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 62.82%), #F3F3F3;
		border-radius: 12px;
		display      : flex;
		align-items  : center;
		grid-gap     : 50px;
	}

	&__pic {
		min-width       : 350px;
		height          : 475px;
		background-color: #D6D6D6;
		border-radius   : 12px;
		overflow        : hidden;
		position        : relative;
	}

	&__img {
		position  : absolute;
		top       : 0;
		left      : 0;
		width     : 100%;
		height    : 100%;
		object-fit: cover;
	}

	&__content {
		display       : flex;
		flex-direction: column;
	}

	&__title {
		text-align: left;
		font-size : 42px;
	}

	&__die {
		display      : flex;
		align-items  : center;
		background   : linear-gradient(89.04deg, #FFFFFF 51.57%, rgba(255, 255, 255, 0.3) 100.14%);
		border-radius: 12px;
	}

	&__like {
		font-size: 32px;
	}

	@media(max-width: $lg) {
		&__block {
			flex-direction: column;
			align-items   : flex-start;
			grid-gap      : 0;
		}

		&__pic {
			min-width    : auto;
			width        : 100%;
			max-width    : 400px;
			height       : 350px;
			margin-bottom: 30px;
		}

		&__text br {
			display: none;
		}
	}

	@media(max-width: $md) {
		& {
			padding-top   : 30px;
			padding-bottom: 30px;
		}

		&__block {
			padding      : 35px 15px;
			border-radius: 0;
			margin       : 0 -15px;
		}

		&__title {
			font-size: 36px;
		}

		&__die {
			flex-direction: column;
			padding       : 15px;
			text-align    : center;
		}

		&__like {
			margin-right : 0;
			margin-bottom: 15px;
		}
	}
}

.contacts {
	padding-top     : 35px;
	padding-bottom  : 84px;
	background-color: var(--bg3);

	&__title {
		margin-bottom: 52px;
	}

	&__items {
		display              : grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap             : 30px;
	}

	&__item {
		display         : flex;
		flex-direction  : column;
		align-items     : center;
		justify-content : center;
		padding-top     : 60px;
		text-align      : center;
		background-color: var(--bg3);
		border          : 1px solid #F1F1F1;
		box-shadow      : 0px 10px 20px rgba(35, 35, 35, 0.04);
		border-radius   : 12px;
	}


	&__icon {
		font-size: 62px;
	}

	&__text {
		&--links {
			display    : flex;
			align-items: center;
		}
	}

	@media(max-width: $lg) {
		&__items {
			grid-template-columns: 1fr;
			grid-gap             : 0;
		}

		&__item {
			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	@media(max-width: $md) {
		& {
			padding-top   : 30px;
			padding-bottom: 50px;
		}

		&__title {
			margin-bottom: 30px;
		}

		&__item {
			padding: 35px 15px;
		}

		&__text {
			&--links {
				align-items   : flex-start;
				flex-direction: column;

				& a {
					border       : none;
					margin-right : 0;
					padding-right: 0;

					&:first-child {
						margin-bottom: 15px;
					}
				}
			}
		}
	}
}

.map {
	height  : 530px;
	overflow: hidden;
	width   : 100%;

	@media(max-width: $md) {
		height: 400px;
	}
}

.footer {
	display        : flex;
	align-items    : center;
	justify-content: space-between;
	background     : var(--bg-3);

	&__logo {
		display    : flex;
		align-items: center;

		&-img {
			width       : 32px;
			margin-right: 20px;
		}
	}

	@media(max-width: $lg) {
		& {
			flex-direction: column;
			text-align    : center;
		}

		&__text {
			margin-top: 15px;
		}
	}
}

.products {
	background    : var(--bg2);
	padding-top   : 62px;
	padding-bottom: 98px;


	@media(max-width: $md) {
		padding-top   : 42px;
		padding-bottom: 42px;
	}

	&__title {
		margin-bottom: 48px;

		@media(max-width: $md) {
			margin-bottom: 32px;
		}
	}

	&-item {
		background   : var(--bg3);
		border       : 1px solid var(--bg1);
		border-radius: var(--radius-small);
		overflow     : hidden;

		&__img {
			height  : 250px;
			position: relative;

			@media(max-width: $md) {
				height: 200px;
			}

			@media(max-width: $sm) {
				height: 150px;
			}

			&:before {
				content      : '';
				display      : block;
				position     : absolute;
				width        : 160px;
				height       : 3px;
				bottom       : 22px;
				left         : calc(50% - 80px);
				background   : #1E1E1E;
				opacity      : 0.25;
				filter       : blur(8px);
				border-radius: 50%;
				z-index      : 2;
			}

			img {
				position: absolute;
				left    : 0;
				top     : 0;

				width     : 100%;
				height    : 100%;
				object-fit: scale-down;

			}
		}

		&__content {
			padding-bottom: 40px;
			text-align    : center;
			position      : relative;

			@media(max-width: $md) {
				padding-bottom: 6px;
			}

		}

		&__note {
			background : #F8BE2A;
			position   : absolute;
			left       : 0;
			bottom     : 0;
			width      : 100%;
			text-align : center;
			font-weight: 500;
			font-size  : 14px;
			padding    : 8px 0;
			line-height: 1;

			@media(max-width: $md) {
				position     : relative;
				border-radius: 8px;
				width        : calc(100% - 12px);
				margin-top   : 10px;
				margin-left  : auto;
				margin-right : auto;
				font-size    : 12px;
			}
		}
	}
}


.cert-item {
	background   : var(--bg3);
	border       : 1px solid var(--bg1);
	box-shadow   : 0px 10px 20px rgba(35, 35, 35, 0.04);
	border-radius: 12px;
	display      : flex;
	align-items  : center;


	@media(max-width: $md) {
		flex-direction: column;
		padding       : 15px;
	}

	&__img {
		background   : #DCDCDC;
		border-radius: 12px;
		padding      : 16px;
		min-width    : 269px;
		width        : 269px;
		position     : relative;
		margin-right : 32px;

		@media(max-width: $md) {
			margin-right : 0;
			margin-bottom: 20px;
			min-width    : 0;
			max-width    : 100%;
		}

		&:before {
			content       : '';
			display       : block;
			width         : 100%;
			padding-bottom: calc(100% * 348 / 237);

		}

		img {
			position     : absolute;
			left         : 16px;
			top          : 16px;
			width        : calc(100% - 32px);
			height       : calc(100% - 32px);
			background   : #fff;
			box-shadow   : 0px 10px 20px rgba(35, 35, 35, 0.04);
			border-radius: 12px;
			overflow     : hidden;
		}
	}

	&__content {
		@media(max-width: $md) {
			text-align: center;
		}

	}

	&__title {
		font-size: 34px;
	}

	&__separator {
		border-top   : 2px dotted var(--color-text-20);
		margin-top   : 20px;
		margin-bottom: 20px;
		width        : calc(100% - 40px);

		@media(max-width: $md) {
			display: none;

		}
	}
}


.dialog {
	&-items {
		width   : 100%;
		grid-gap: 45px;

		@media (max-width:$lg) {
			flex-wrap: wrap;
		}
	}

	&-pic {
		min-width    : 300px;
		width        : 300px;
		height       : 300px;
		position     : relative;
		border       : 1px solid #DBDBDB;
		overflow     : hidden;
		border-radius: 12px;
	}

	&-img {
		position  : absolute;
		top       : 0;
		left      : 0;
		right     : 0;
		bottom    : 0;
		width     : 100%;
		height    : 100%;
		object-fit: scale-down;
	}

	&-list li {
		list-style   : disc inside;
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&-btn {
		width      : 210px;
		font-size  : 18px;
		font-weight: 600;
	}

	&-price {
		background-color: var(--bg-3);
		border          : 1px solid #F1F1F1;
		box-shadow      : 0px 10px 20px rgba(35, 35, 35, 0.04);
		border-radius   : 12px;
		height          : max-content;
		width           : 240px;
		min-width       : 240px;
	}

	@media(max-width: $md) {
		& {
			padding: 0;
		}

		&-items {
			grid-gap      : 0;
			flex-wrap     : nowrap;
			flex-direction: column;
		}

		&-price {
			width    : 100%;
			min-width: 0;
		}

		&-pic {
			width        : 100%;
			max-width    : 100%;
			margin-bottom: 20px;
			min-width    : 0;
		}

		&-list,
		&-info {
			margin-bottom: 20px;
		}
	}
}

.service {
	background    : var(--bg1);
	padding-top   : 64px;
	padding-bottom: 92px;
	position      : relative;
	overflow      : hidden;

	@media(max-width: $md) {
		padding-top   : 42px;
		padding-bottom: 42px;
	}

	&__content {
		position: relative;
		z-index : 2;
	}

	&__title {
		margin-bottom: 48px;

		@media(max-width: $md) {
			margin-bottom: 32px;
		}
	}

	&-item {
		background: var(--bg3);

		box-shadow   : 0px 10px 20px rgba(35, 35, 35, 0.04);
		border-radius: 12px;
		padding      : 8px;
		display      : flex;
		align-items  : center;

		&__img {
			min-width   : 204px;
			width       : 204px;
			margin-right: 48px;

			border-radius: 16px;
			overflow     : hidden;


			@media(max-width: $md) {
				min-width   : 124px;
				width       : 124px;
				margin-right: 18px;
			}

			@media(max-width: $sm) {
				min-width   : 100px;
				width       : 100px;
				margin-right: 12px;
			}
		}
	}

	&-tiles {
		position             : absolute;
		display              : grid;
		grid-template-columns: repeat(3, 100px);
		grid-template-rows   : repeat(4, 100px);

		@media(max-width: $lg) {
			grid-template-columns: repeat(3, 80px);
			grid-template-rows   : repeat(4, 80px);
		}

		@media(max-width: $md) {
			grid-template-columns: repeat(3, 50px);
			grid-template-rows   : repeat(4, 50px);
		}

		&--left {
			bottom: 0;
			left  : 0;
		}

		&--right {
			right: 0;
			top  : 0;
		}
	}
}

.pray {
	background    : linear-gradient(83.28deg, #660C11 0%, rgba(0, 0, 0, 0) 100%), #E84139;
	display       : flex;
	align-items   : center;
	padding-top   : 32px;
	padding-bottom: 32px;
	position      : relative;
	overflow      : hidden;

	@media(max-width: $lg) {
		flex-direction: column;
	}

	&__content {
		display       : flex;
		flex-direction: column;
		align-items   : flex-start;
		position      : relative;
		z-index       : 2;
	}

	&__title {
		font-size    : 48px;
		color        : #FFFFFF;
		font-family  : var(--font-alt);
		margin-bottom: 16px;

		@media(max-width: $md) {
			font-size: 32px;
		}

		span {
			font-family: inherit;
		}
	}

	&__sign {
		font-size    : 38px;
		color        : var(--color-primary);
		font-family  : var(--font-alt);
		background   : var(--bg3);
		border-radius: 6px 0 0 6px;
		padding      : 12px 15px;
		position     : relative;

		--height   : 72px;
		height     : var(--height);
		display    : flex;
		align-items: center;

		@media(max-width: $md) {
			font-size: 24px;
			--height : 48px;
		}

		@media(max-width: $sm) {
			font-size: 20px;
			--height : 42px;
		}

		&:before {
			content      : '';
			position     : absolute;
			--size       : calc(var(--height) / var(--root-from-2));
			width        : var(--size);
			height       : var(--size);
			left         : calc(100% - var(--size) / 2);
			top          : calc(50% - var(--size) / 2);
			border-radius: 6px;
			background   : linear-gradient(45deg, rgba(0, 0, 0, 0) 50%, var(--bg3) 50%);
			transform    : rotateZ(45deg) skew(10deg, 10deg) scale(0.9);
		}
	}

	&__description {
		margin-top: 40px;
		color     : #fff;

		@media(max-width: $lg) {
			margin-top: 20px;
		}
	}

	&-tiles {
		position             : absolute;
		display              : grid;
		grid-template-columns: repeat(3, 100px);
		grid-auto-rows       : 100px;

		@media(max-width: $lg) {
			grid-template-columns: repeat(3, 80px);
			grid-auto-rows       : 80px;
		}

		@media(max-width: $md) {
			grid-template-columns: repeat(3, 50px);
			grid-auto-rows       : 50px;
		}

		&--left {
			bottom: 0;
			left  : 0;
		}

		&--right {
			right: 0;
			top  : 0;

		}

		&-politic br {
			display: none;
		}
	}
}

.-elem- {
	transition-duration       : 0.5s;
	transition-timing-function: ease;

	&fade-:not(&show-) {
		visibility: hidden;
		opacity   : 0;
	}

	&scale-:not(&show-) {
		transform: scale(0);
		opacity  : 0;
	}

	&rotate-:not(&show-) {
		transform: rotate(540deg);
		opacity  : 0;
	}

	&up-:not(&show-) {
		transform: translateY(50px);
		opacity  : 0;
	}

	&down-:not(&show-) {
		transform: translateY(-50px);
		opacity  : 0;
	}

	&left-:not(&show-) {
		transform: translateX(50px);
		opacity  : 0;
	}

	&right-:not(&show-) {
		transform: translateX(-50px);
		opacity  : 0;
	}
}

@for $i from 1 to 11 {
	.-delay-#{calc($i * 100)}- {
		transition-delay: #{calc($i / 10) + s};
	}
}

.product-dialog-prop {
	display: flex;

	&__dots {
		flex-grow    : 1;
		height       : 1em;
		border-bottom: 2px dotted currentColor;
		margin-left  : 5px;
		margin-right : 5px;


	}
}

picture {
	position: static !important;
}